import React from 'react'
import { FormPreRegistration } from './FormPreRegistration'

export const PreRegistrationPage = () => {
    return (
        <>
            <FormPreRegistration />
        </>
    )
}
